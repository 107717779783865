.admin-home-container {
    font-family: 'Inter', sans-serif;
    text-align: center;
    background-color: var(--primary); /* Fond bleu foncé */
    color: var(--secondary); /* Texte pâle pour un bon contraste */
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
  }
  
  .admin-header {
    margin-bottom: 40px;
  }
  
  .admin-title {
    color: var(--accent); /* Titre en jaune pâle pour le contraste */
    font-size: 2.5rem;
    font-weight: bold;
    text-shadow: 2px 2px 4px var(--shadowBlueDark);
  }
  
  .admin-nav {
    margin-bottom: 40px;
  }
  
  .admin-nav-list {
    list-style: none;
    padding: 0;
    display: flex;
    gap: 20px;
  }
  
  .admin-nav-item {
    margin: 0;
  }
  
  .admin-nav-link {
    text-decoration: none;
    font-size: 1.2rem;
    font-weight: 500;
    color: var(--secondary); /* Texte pâle */
    padding: 12px 24px;
    background-color: var(--backgroundBlock); /* Fond translucide */
    border: 1px solid var(--borderAtGlance); /* Bordure subtile pour le contraste */
    border-radius: 8px;
    transition: background-color 0.3s ease, transform 0.2s ease, color 0.3s ease;
  }
  
  .admin-nav-link:hover {
    background-color: var(--accent); /* Couleur d'accentuation */
    color: var(--primary); /* Texte inversé pour l'accent */
    transform: translateY(-3px); /* Effet de survol */
  }
  
  .admin-logout-button {
    font-size: 1rem;
    font-weight: bold;
    color: var(--primary); /* Texte foncé */
    background-color: var(--accent); /* Fond jaune pâle */
    padding: 12px 24px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
  }
  
  .admin-logout-button:hover {
    background-color: var(--secondary); /* Couleur pâle pour contraste */
    transform: translateY(-3px);
    color: var(--primary); /* Texte foncé pour contraste */
  }
  
  .admin-logout-button:focus {
    outline: 2px solid var(--accent);
    outline-offset: 2px;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .admin-nav-list {
      flex-direction: column;
      gap: 15px;
    }
  
    .admin-nav-link {
      font-size: 1rem;
      padding: 10px 20px;
    }
  
    .admin-logout-button {
      padding: 10px 20px;
      font-size: 0.9rem;
    }
  }