.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background: var(--primary);
  margin-bottom: 50px;
}

.logo-section {
  display: flex;
  align-items: center;
}

.logo {
  height: 40px;
  margin-right: 15px;
}

h1 {
  font-size: 24px;
  color: var(--secondary); /* Remplacé white par var(--secondary) */
}

.admin-text {
  font-size: 16px;
  color: var(--accent); /* Remplacé #FFD700 par var(--accent) */
  margin-left: 5px;
}

.nav-section {
  display: flex;
  gap: 20px;
}

.nav-section button {
  background-color: var(--backgroundBlock); /* Remplacé rgba(255, 255, 255, 0.2) par var(--backgroundBlock) */
  border: none;
  color: var(--secondary); /* Remplacé white par var(--secondary) */
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 20px;
  cursor: pointer;
}

.nav-section button:hover {
  background-color: var(--borderAtGlance); /* Remplacé rgba(255, 255, 255, 0.4) par var(--borderAtGlance) */
}

.logout-button {
  background-color: var(--redAlerte); /* Remplacé #FF4D4D par var(--redAlerte) */
  border: none;
  color: var(--secondary); /* Remplacé white par var(--secondary) */
  padding: 10px 20px;
  border-radius: 20px;
  cursor: pointer;
  position: absolute;
  right: 20px;
}

.logout-button:hover {
  background-color: var(--redAlerte); /* Remplacé #FF1A1A par var(--redAlerte) */
}