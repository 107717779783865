/* Conteneur principal de la page */
.bulletin-neige-page {
  min-height: 100vh;
  align-items: center;
  justify-content: center;
}

/* Conteneur du formulaire */
.bulletin-neige-container {
  max-width: 700px; /* Augmentation de la largeur maximale */
  width: 100%;
  margin: auto;
  padding: 40px; /* Augmentation du padding pour plus d'espace */
  background-color: #FFFFFF; /* Blanc pour le contraste */
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Ombre plus douce */
  border: 1px solid #e0e0e0; /* Bordure légère */
}

/* Titre principal */
.bulletin-neige-container h2 {
  font-size: 2rem; /* Augmentation de la taille de police */
  color: #2c3e50; /* Couleur principale plus sombre */
  text-align: center;
  margin-bottom: 30px; /* Augmentation de la marge inférieure */
}

/* Groupes de champs du formulaire */
.bulletin-neige-form-group {
  margin-bottom: 25px; /* Augmentation de la marge inférieure */
  display: flex;
  flex-direction: column;
}

/* Labels des champs */
.bulletin-neige-form-group label {
  display: block;
  margin-bottom: 10px; /* Augmentation de la marge inférieure */
  font-weight: 600; /* Légèrement moins gras */
  color: #2c3e50; /* Couleur principale plus sombre */
  font-size: 1rem; /* Taille de police standard */
}

/* Champs de saisie texte */
.bulletin-neige-input {
  padding: 14px; /* Augmentation du padding */
  font-size: 1rem;
  border: 1px solid #bdc3c7; /* Bordure plus claire */
  border-radius: 8px;
  background-color: #f9f9f9; /* Fond des champs légèrement gris */
  color: #2c3e50; /* Texte plus sombre */
  outline: none;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.bulletin-neige-input:focus {
  border-color: #3498db; /* Bordure bleue au focus */
  box-shadow: 0 0 8px rgba(52, 152, 219, 0.3); /* Ombre au focus */
}

/* Éditeur React Quill */
.bulletin-neige-editor {
  min-height: 250px; /* Augmentation de la hauteur minimale */
  background-color: #fdfdfd; /* Fond de l'éditeur */
  border: 1px solid #bdc3c7; /* Bordure */
  border-radius: 8px;
  font-size: 1rem;
  color: #2c3e50;
  padding: 10px; /* Padding interne */
}

.bulletin-neige-editor .ql-toolbar {
  background-color: #ecf0f1; /* Fond de la barre d'outils */
  border: 1px solid #bdc3c7;
  border-radius: 8px 8px 0 0;
}

.bulletin-neige-editor .ql-container {
  border-top: none;
  border-radius: 0 0 8px 8px;
  padding: 10px; /* Padding interne */
}

/* Champs pour l'upload d'image */
.bulletin-neige-file-input {
  padding: 10px; /* Augmentation du padding */
  font-size: 1rem;
  color: #2c3e50;
  border: 1px solid #bdc3c7;
  border-radius: 8px;
  background-color: #f9f9f9;
  transition: border-color 0.3s ease;
}

.bulletin-neige-file-input:focus {
  border-color: #3498db;
  outline: none;
  box-shadow: 0 0 8px rgba(52, 152, 219, 0.3);
}

/* Indicateur de progression de l'upload */
.bulletin-neige-upload-progress {
  margin-top: 15px; /* Augmentation de la marge supérieure */
  color: #2c3e50;
  font-size: 1rem;
}

.bulletin-neige-upload-progress progress {
  width: 100%;
  height: 12px; /* Augmentation de la hauteur */
  border-radius: 6px;
  background-color: #ecf0f1;
  margin-top: 8px; /* Marge supérieure */
}

/* Affichage des erreurs */
.bulletin-neige-upload-error {
  color: #e74c3c; /* Rouge d'alerte */
  font-weight: bold;
  margin-top: 15px;
  font-size: 1rem;
}

/* Bouton de soumission */
.bulletin-neige-submit-button {
  width: 100%;
  background-color: #3498db; /* Bouton principal bleu */
  color: #ffffff; /* Texte blanc */
  font-size: 1.1rem; /* Taille de police augmentée */
  font-weight: 600;
  padding: 14px; /* Augmentation du padding */
  border: none;
  border-radius: 8px;
  cursor: pointer;
  text-align: center;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.bulletin-neige-submit-button:hover {
  background-color: #2980b9; /* Survol plus sombre */
}

.bulletin-neige-submit-button:disabled {
  background-color: #95a5a6; /* Couleur désactivée grise */
  cursor: not-allowed;
}

.bulletin-neige-submit-button:active {
  transform: scale(0.98);
}

/* Organisation des Champs de Profondeur de Neige en Ligne */
.bulletin-neige-snow-depth-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}

.bulletin-neige-snow-depth-field {
  display: flex;
  flex-direction: column;
}

/* Adaptation de la Taille des Images au Conteneur */
.bulletin-neige-image,
.bulletin-neige-image-preview img,
.bulletin-neige-existing-image img {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  margin-top: 10px; /* Augmentation de la marge supérieure */
}

/* Ajustements Optionnels pour une Meilleure Présentation */
.bulletin-neige-image-preview,
.bulletin-neige-existing-image {
  margin-top: 20px; /* Augmentation de la marge supérieure */
}

.bulletin-neige-existing-image label,
.bulletin-neige-image-preview label {
  font-weight: 600;
  margin-bottom: 10px;
  color: #2c3e50;
  font-size: 1rem;
}

/* Responsive Design pour les Petits Écrans */
@media (max-width: 600px) {
  .bulletin-neige-snow-depth-field {
    flex: 1 1 100%;
  }

  .bulletin-neige-container {
    padding: 20px; /* Réduction du padding sur petits écrans */
  }

  .bulletin-neige-container h2 {
    font-size: 1.75rem;
    margin-bottom: 20px;
  }

  .bulletin-neige-editor {
    min-height: 200px;
  }

  .bulletin-neige-submit-button {
    font-size: 1rem;
    padding: 12px;
  }
}