/* Global Styles */
body {
  background-color: var(--dark);
  margin-bottom: 150px;
}

/*Annecy Titre*/
.title {
  color: white;
}

/* Containers */
.prevision-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  flex-direction: row;
  align-content: space-around;
}

.combined-day-block {
  display: flex;
  background-color: var(--backgroundBlock);
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 10px;
  flex-wrap: wrap;
  flex-direction: row;
}

/* API BLOCK */
.day-block {
  background-color: var(--primary);
  border-radius: 12px;
  color: var(--secondary);
  width: 300px;
  padding: 15px;
}

.day-block button {
  background-color: var(--accent);
  color: var(--primary);
  border: none;
  border-radius: 5px;
  padding: 8px 12px;
  cursor: pointer;
}

.day-block button:hover {
  opacity: 0.7;
}

/* Expert Blocks */
.expert-block {
  border-radius: 15px;
  border: 3px solid var(--accent);
  background: var(--primary);
  margin-left: 10px;
  width: 320px;
}

.expert-block h3 {
  text-align: center;
  color: var(--secondary);
  margin-bottom: 10px;
}

.api-block {
  border-radius: 15px;
  background: var(--shadowBlueDark);
}

/* Prevision Styles */
.prevision {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}

.day-part {
  display: flex;
  flex-direction: column;
  margin: 5px;
}

.weather-icon {
  width: 50px;
  height: 50px;
  margin-bottom: 10px;
}

/* Headings */
h3 {
  text-align: center;
  color: var(--secondary);
  margin-bottom: 10px;
}

h4 {
  color: var(--secondary);
  text-align: center;
}

/* Buttons */
button {
  margin-top: 10px;
  padding: 5px 10px;
  background-color: var(--accent);
  color: var(--primary);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 10px;
}

button:hover {
  opacity: 0.7;
}

.floating-button {
  padding: 15px 30px;
  border-radius: 13px;
  background: var(--accent);
  box-shadow: 0px 5px 50px 0px var(--shadowBlueDark);
  color: var(--primary);
  border: none;
  cursor: pointer;
  font-size: 16px;
}

.floating-button:disabled {
  background-color: #aaa;
  cursor: not-allowed;
}

/* Side Panel */
.side-panel {
  position: fixed;
  top: 0%;
  right: 0%;
  width: 30%;
  height: 50%;
  background: rgba(2, 52, 83, 0.6);
  border-radius: 50px;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(20px);
  padding: 20px;
  margin: 20px;
  z-index: 1000;
  overflow-y: auto;
  transition: transform 800ms ease-in-out;
  overflow: auto;
  scrollbar-width: none;
}

.side-panel h3 {
  color: var(--secondary);
  margin-bottom: 15px;
}

.side-panel .input-standard {
  width: 60px;
  padding: 8px;
  border: none;
  border-radius: 6px;
  background-color: var(--temperatureBackground);
  color: var(--secondary);
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  box-sizing: border-box;
  margin-bottom: 10px;
}

.side-panel .icon-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.side-panel .icon-choice {
  width: 50px;
  height: 50px;
  cursor: pointer;
  border: 2px solid transparent;
  border-radius: 5px;
  transition: border 0.3s;
}

.side-panel .icon-choice:hover {
  background-color: var(--backgroundBlock);
}

.icon-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
}

.icon-option,
.icon-choice {
  width: 50px;
  height: 50px;
  cursor: pointer;
}

.icon-choice.selected {
  border: 2px solid var(--secondary);
}

/* Floating Button Bandeau */
.bandeau-floating-button {
  display: flex;
  position: fixed;
  bottom: 0px;
  width: 100%;
  padding: 20px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  background: var(--backgroundOpacity);
  box-shadow: 0px -10px 90px 0px var(--shadowBlueDark);
  backdrop-filter: blur(7.5px);
}

/* Inputs */
.input-standard {
  width: 60px;
  padding: 8px;
  border: none;
  border-radius: 6px;
  background-color: var(--temperatureBackground);
  color: var(--secondary);
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  box-sizing: border-box;
  margin-bottom: 10px;
}

.side-panel .input-standard {
  width: 60px;
}

.iso0-field {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;
}

.iso0-field .input-label-group {
  width: 48%;
}

.input-label-group {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 10px;
}

.input-label-group label {
  font-weight: bold;
  margin-bottom: 5px;
  color: var(--secondary);
}

.input-standard:focus {
  border-color: var(--secondary);
  box-shadow: 0 0 5px var(--shadowBlue);
  outline: none;
}

input[type="number"]::-webkit-inner-spin-button, 
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.bulletin-textarea {
  border-radius: 15px;
  border: 1px solid var(--temperatureBackground);
  background: transparent;
  padding: 10px;
  color: var(--secondary);
  width: 93%;
  height: 310px;
  resize: vertical;
}

.bulletin-textarea::placeholder {
  color: var(--temperatureBackground);
  opacity: 1;
}

/* Switches */
.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 24px;
  margin-right: 10px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.switch .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--temperatureBackground);
  transition: 0.4s;
  border-radius: 24px;
}

.switch .slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 3px;
  bottom: 3px;
  background-color: var(--secondary);
  transition: 0.4s;
  border-radius: 50%;
}

.switch.round .slider:before {
  height: 14px;
  width: 14px;
  left: 3px;
  bottom: 3px;
  background-color: rgb(14, 35, 60);
}

.switch input:checked + .slider {
  background-color: var(--accent);
}

.switch input:checked + .slider:before {
  transform: translateX(14px);
  background-color: var(--primary);
}

.switch-orange .slider:before {
  background: var(--orangeAlerte);
}

.switch-orange input:checked + .slider {
  background: var(--secondary);
  border-radius: 100px;
}

.switch-red .slider:before {
  background: var(--redAlerte);
}

.switch-red input:checked + .slider {
  background: var(--secondary);
  border-radius: 100px;
}

.switch input:checked + .slider:before {
  transform: translateX('100%');
}

.switch-label {
  margin-right: 20px;
  font-size: 14px;
  vertical-align: middle;
}

.alert-switches {
  display: flex;
  align-items: center;
}

.alert-switches .switch {
  margin-right: 10px;
}

.alert-switches .switch-label {
  margin-right: 20px;
}

/* Reliability Selector */
.reliability-selector {
  display: flex;
  align-items: center;
  margin-left: 20px;
}

.reliability-selector label {
  font-size: 14px;
  color: var(--secondary);
}

.reliability-selector select {
  margin-left: 10px;
  padding: 5px;
  background-color: var(--temperatureBackground);
  border: 1px solid var(--secondary);
  color: var(--secondary);
  border-radius: 10px;
  width: auto;
}

.reliability-selector select:focus {
  outline: none;
  border-color: var(--secondary);
  box-shadow: 0 0 5px var(--shadowBlue);
}

/* Manual Shift Button */
.manual-shift-button {
  margin-top: 20px;
  text-align: center;
}

.manual-shift-button button {
  padding: 10px 20px;
  background-color: var(--accent);
  color: var(--primary);
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.manual-shift-button button:disabled {
  background-color: var(--shadowBlueDark);
  cursor: not-allowed;
}

/* Message Banner */
.message-banner {
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  padding: 15px 20px;
  border-radius: 8px;
  color: var(--secondary);
  opacity: 0;
  transform: translateY(-20px);
  animation: slideIn 0.5s forwards, slideOut 0.5s 4.5s forwards;
  z-index: 200;
}

.message-banner.success {
  background-color: var(--wind50);
}

.message-banner.error {
  background-color: var(--redAlerte);
}

@keyframes spin {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
}

@keyframes slideIn {
  to {
      opacity: 1;
      transform: translateY(0);
  }
}

@keyframes slideOut {
  to {
      opacity: 0;
      transform: translateY(-20px);
  }
}

/* Loader */
.loader {
  border: 4px solid var(--borderAtGlance);
  border-top: 4px solid var(--primary);
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
}

/* Auto Mode Switch */
.vacation-mode-switch {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
  background-color: var(--temperatureBackground);
  border-radius: 8px;
  max-width: 200px;
  margin: 0 auto;
  font-family: Arial, sans-serif;
  font-size: 14px;
  color: var(--secondary);
  margin-bottom: 20px;
}

/*Mode Montagne*/

.snowfall-summary {
  background-color: var(--backgroundBlock);
  padding: 20px;
  margin: 20px 0;
  border-radius: 8px;
  text-align: center;
  color: var(--secondary);
}


