/* Bandeaux.css */

/* Variables CSS pour une meilleure gestion des thèmes */
:root {
  --primary: #4A90E2; /* Légère modification pour une teinte plus moderne */
  --secondary: #ffffff;
  --background: #f9fafb; /* Fond légèrement plus clair */
  --border: #e0e0e0;
  --shadow: rgba(0, 0, 0, 0.05); /* Ombre plus subtile */
  --error: #e74c3c;
  --success: #2ecc71;
  --font-color: #333333; /* Couleur de texte plus douce */
  --input-bg: #ffffff; /* Fond des champs d'entrée */
  --input-border: #ced4da; /* Bordure des champs d'entrée */
  --input-focus-border: var(--primary);
  --button-hover: #357ABD;
  --toggle-bg: #ddd;
  --toggle-active-bg: var(--primary);
  --toggle-knob: #fff;
}

/* Global Styles */
body {
  margin: 0;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

/* Conteneur du formulaire */
.bandeau-container {
  max-width: 700px;
  width: 100%;
  margin: 60px auto; /* Ajout d'un margin-top pour aérer du haut */
  padding: 40px;
  background-color: var(--secondary);
  border-radius: 16px; /* Bordure plus arrondie */
  box-shadow: 0 8px 24px var(--shadow); /* Ombre plus douce et étendue */
  border: 1px solid var(--border);
}

/* Titre principal */
.bandeau-container h2 {
  font-size: 2.5rem; /* Taille de police augmentée */
  color: var(--primary);
  text-align: center;
  margin-bottom: 40px; /* Espacement accru sous le titre */
  font-weight: 700;
}

/* Groupes de champs du formulaire */
.bandeau-form-group {
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
}

/* Labels des champs */
.bandeau-form-group label {
  margin-bottom: 8px;
  font-weight: 600;
  color: var(--font-color);
  font-size: 1rem;
}

/* Champs de saisie texte, select et file-input */
.bandeau-input,
.bandeau-select,
.bandeau-file-input {
  padding: 12px 16px;
  font-size: 1rem;
  border: 1px solid var(--input-border);
  border-radius: 8px;
  background-color: var(--input-bg);
  color: var(--font-color);
  outline: none;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.bandeau-input::placeholder,
.bandeau-select::placeholder,
.bandeau-file-input::placeholder {
  color: #a1a1a1;
}

.bandeau-input:focus,
.bandeau-select:focus,
.bandeau-file-input:focus {
  border-color: var(--input-focus-border);
  box-shadow: 0 0 0 4px rgba(74, 144, 226, 0.1);
}

/* Éditeur React Quill */
.bandeau-editor {
  min-height: 200px;
  background-color: var(--input-bg);
  border: 1px solid var(--input-border);
  border-radius: 8px;
  font-size: 1rem;
  color: var(--font-color);
  padding: 12px;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.bandeau-editor .ql-toolbar {
  background-color: #f1f3f5;
  border: none;
  border-radius: 8px 8px 0 0;
}

.bandeau-editor .ql-container {
  border-top: 1px solid var(--input-border);
  border-radius: 0 0 8px 8px;
  padding: 12px;
}

/* Toggle Switch */
.bandeau-toggle-container {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.bandeau-switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 28px;
  margin-right: 12px;
}

.bandeau-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.bandeau-slider-round {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--toggle-bg);
  transition: background-color 0.4s;
  border-radius: 28px;
}

.bandeau-slider-round::before {
  position: absolute;
  content: "";
  height: 22px;
  width: 22px;
  left: 3px;
  bottom: 3px;
  background-color: var(--toggle-knob);
  transition: transform 0.4s;
  border-radius: 50%;
}

.bandeau-switch input:checked + .bandeau-slider-round {
  background-color: var(--toggle-active-bg);
}

.bandeau-switch input:checked + .bandeau-slider-round::before {
  transform: translateX(22px);
}

/* Indicateur de progression de l'upload */
.bandeau-upload-progress {
  margin-top: 20px;
  color: var(--font-color);
  font-size: 0.95rem;
}

.bandeau-upload-progress progress {
  width: 100%;
  height: 10px;
  border-radius: 5px;
  background-color: var(--border);
  margin-top: 8px;
  appearance: none;
}

.bandeau-upload-progress progress::-webkit-progress-bar {
  background-color: var(--border);
  border-radius: 5px;
}

.bandeau-upload-progress progress::-webkit-progress-value {
  background-color: var(--primary);
  border-radius: 5px;
}

/* Affichage des erreurs */
.bandeau-upload-error {
  color: var(--error);
  font-weight: 600;
  margin-top: 20px;
  font-size: 0.95rem;
}

/* Bouton de soumission */
.bandeau-submit-button {
  width: 100%;
  background-color: var(--primary);
  color: var(--secondary);
  font-size: 1.1rem;
  font-weight: 600;
  padding: 14px 20px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  text-align: center;
  transition: background-color 0.3s ease, transform 0.2s ease, box-shadow 0.3s ease;
  margin-top: 20px;
}

.bandeau-submit-button:hover {
  background-color: var(--button-hover);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.bandeau-submit-button:disabled {
  background-color: #bdc3c7;
  cursor: not-allowed;
}

.bandeau-submit-button:active {
  transform: scale(0.98);
}

/* Adaptation de la Taille des Images au Conteneur */
.image,
.image-preview img,
.existing-image img {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  margin-top: 10px;
}

.image-preview,
.existing-image {
  margin-top: 20px;
}

.image-preview label,
.existing-image label {
  font-weight: 600;
  margin-bottom: 10px;
  color: var(--font-color);
  font-size: 1rem;
}

/* Responsive Design */
@media (max-width: 600px) {
  .bandeau-container {
    padding: 30px 20px;
    margin: 30px 10px;
  }

  .bandeau-container h2 {
    font-size: 2rem;
    margin-bottom: 25px;
  }

  .bandeau-editor {
    min-height: 180px;
  }

  .bandeau-submit-button {
    font-size: 1rem;
    padding: 12px 16px;
  }
}